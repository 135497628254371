import { AppProps } from 'next/app';
import Router, { useRouter } from 'next/router';
import Script from 'next/script';
import localFont from 'next/font/local';
import nProgress from 'nprogress';
import mixpanel from 'mixpanel-browser';
import Head from 'next/head';
import {
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import * as Sentry from '@sentry/nextjs';
import smoothscroll from 'smoothscroll-polyfill';
import 'tailwindcss/tailwind.css';
import '../styles/global.css';
import '../styles/nprogress.css';
import { MixpanelContext, trackVisitOnBackend } from '@utils/tracking';
import initAuth from '@utils/firebase/initAuth';
import React, { useEffect, useState } from 'react';
import { trackDidBecomeActive, trackPageView } from '@customtypes/events';
import { useUser, withUser } from 'next-firebase-auth';
import { ABTestsContext } from '@hooks/useABTest';

import { NextPageContext } from 'next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  getActiveABTests,
  parseABTestCookiesFromRequestHeader,
} from '@utils/ab-testing/ab-testing-utils';
import { ABTestToBucketMap } from '@customtypes/ab-tests';
import { ABTestBucket, ABTestNames } from '@utils/ab-testing/ab-tests';
dayjs.extend(utc);
import link_image_twitter from '../public/images/seo/link_image_twitter.png';
import { SpeedInsights } from '@vercel/speed-insights/next';
import { getCookieMap } from '@utils/cookie-utils';
import { UserReportLessonFilesContext } from '@hooks/useUserReportLessonFiles';
import { ExecutableLessonFile } from '@customtypes/parsedLesson';
import {
  Notification,
  NotificationQueueContext,
} from '@hooks/useNotificationQueue';
import {
  RoutingHistoryContext,
  RoutingHistoryEntry,
} from '@hooks/useRoutingHistory';
import { VercelToolbar } from '@vercel/toolbar/next';
import { FlagValues } from '@vercel/flags/react';
import { isUserCourseEngineer } from '@utils/content-utils';

nProgress.configure({ showSpinner: false });

// load the font files
const aeonik = localFont({
  src: [
    {
      path: '../public/fonts/AeonikPro-Bold.woff2',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../public/fonts/AeonikPro-BoldItalic.woff2',
      weight: '700',
      style: 'italic',
    },
    {
      path: '../public/fonts/AeonikPro-Medium.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../public/fonts/AeonikPro-MediumItalic.woff2',
      weight: '500',
      style: 'italic',
    },
    {
      path: '../public/fonts/AeonikPro-Regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../public/fonts/AeonikPro-RegularItalic.woff2',
      weight: '400',
      style: 'italic',
    },
  ],
  display: 'swap',
});

const aeonikFono = localFont({
  src: [
    {
      path: '../public/fonts/AeonikFono-Bold.woff2',
      weight: '700',
      style: 'normal',
    },
  ],
  display: 'swap',
});

const aeonikMono = localFont({
  src: [
    {
      path: '../public/fonts/AeonikMono-Regular.woff2',
      weight: '400',
      style: 'normal',
    },
  ],
  display: 'swap',
});

initAuth();

type CustomAppProps = AppProps & {
  abTests: ABTestToBucketMap;
};

function MyApp({ Component, pageProps, abTests }: CustomAppProps) {
  mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_KEY || '', {
    api_host: 'https://tracker-proxy.getmimo.com',
    // super properties cookie expiration (in days)
    cookie_expiration: 1,
    track_links_timeout: 700,
  });

  // NOTES: In case we're dealing with SSG pages, we don't run
  // getInitialProps and need to make sure to grab the abTests
  // from the cookies ourselves.
  if (
    typeof window !== 'undefined' &&
    (!abTests || Object.keys(abTests).length === 0)
  ) {
    const activeABTests = getActiveABTests();
    const clientSideCookieMap = getCookieMap(document.cookie);

    abTests = Object.keys(activeABTests).reduce((prev, curr) => {
      const bucket = clientSideCookieMap[curr as ABTestNames];

      if (bucket) {
        prev[curr as ABTestNames] = bucket as ABTestBucket;
      }

      return prev;
    }, {} as ABTestToBucketMap);
  }

  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            gcTime: 1000 * 60 * 60 * 24, // 24 hours
          },
        },
      }),
  );
  const router = useRouter();
  const user = useUser();

  const [userReportLessonFiles, setUserReportLessonFiles] = useState<
    ExecutableLessonFile[]
  >([]);
  const [routingHistoryEntries, setRoutingHistoryEntries] = useState<
    RoutingHistoryEntry[]
  >([]);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [previousLocation, setPreviousLocation] = useState<string | undefined>(
    undefined,
  );
  const canonicalUrl = (
    'https://mimo.org' + (router.asPath === '/' ? '' : router.asPath)
  ).split('?')[0];

  if (typeof window !== 'undefined') {
    smoothscroll.polyfill();
  }

  /**
   * Set up nProgress site navigation loading progress bar
   * The extra logic makes sure that when the overview drawer
   * is toggled (manipulating the URL query params), the
   * loading progress bar will not show up.
   */
  useEffect(() => {
    function canShowNavigationProgressBar(url: string) {
      try {
        const previousUrl = new URL(`${previousLocation}`);
        const previousPath = previousUrl?.pathname;
        const isPathChanged = previousPath !== url.split('?')[0];
        return isPathChanged;
      } catch (error) {
        return true;
      }
    }

    function handleRouteChangeStart(url: string) {
      canShowNavigationProgressBar(url) && nProgress.start();
      if (url.split('?')[0] === router.asPath.split('?')[0]) {
        return;
      }
      const entry: RoutingHistoryEntry = {
        pathname: router.pathname,
        asPath: router.asPath,
        query: router.query,
      };
      const newRoutingHistoryEntries = [entry, ...routingHistoryEntries].slice(
        0,
        10,
      );
      setRoutingHistoryEntries(newRoutingHistoryEntries);
    }

    function handleRouteChangeComplete() {
      nProgress.done();
    }

    function handleRouteChangeError() {
      nProgress.done();
    }

    Router.events.on('routeChangeStart', handleRouteChangeStart);
    Router.events.on('routeChangeComplete', handleRouteChangeComplete);
    Router.events.on('routeChangeError', handleRouteChangeError);

    return () => {
      Router.events.off('routeChangeStart', handleRouteChangeStart);
      Router.events.off('routeChangeComplete', handleRouteChangeComplete);
      Router.events.off('routeChangeError', handleRouteChangeError);
    };
  }, [previousLocation, router, routingHistoryEntries]);

  useEffect(() => {
    // register ab tests as people properties
    mixpanel.people.set({ ...abTests });

    // register ab tests as super properties
    mixpanel.register({
      ...abTests,
    });
  }, [abTests]);

  // track did become active on page loads on focus events
  useEffect(() => {
    if (user.id) {
      trackDidBecomeActive(mixpanel);
      window.addEventListener('focus', function () {
        trackDidBecomeActive(mixpanel);
      });
    }
  }, [user]);

  useEffect(() => {
    if (user.id) {
      Sentry.setUser({
        id: user.id,
        name: user.displayName,
        email: user.email || '',
      });
      Sentry.setTag(
        'environment',
        window.location.hostname === 'localhost'
          ? 'development'
          : window.location.href.indexOf('/ssr/') > -1
            ? 'preview'
            : 'production',
      );
    }
  }, [user]);

  useEffect(() => {
    if (user.id) {
      trackVisitOnBackend(user);
      mixpanel.identify(user.id);
      mixpanel.people.set({ $email: user.email });
      (window as any)._cio
        ? (window as any)._cio.identify({ id: user.id, email: user.email })
        : null;
    }
  }, [user]);

  useEffect(() => {
    // This check prevents sending duplicate pageview events since we have 2 dependencies in the effect and the effect would run twice.
    if (previousLocation != window.location.href) {
      trackPageView(mixpanel, {
        navigatedFrom: previousLocation,
        navigatedTo: window.location.href,
      });
      (window as any)._cio
        ? (window as any)._cio.page(window.location.href, {
            navigatedFrom: previousLocation,
          })
        : null;
      setPreviousLocation(window.location.href);
    }
  }, [router.pathname, router.query]);

  const isMimoEmployee = isUserCourseEngineer(user);

  return (
    <main className="h-full">
      <style global jsx>{`
        :root {
          --font-aeonik: ${aeonik.style.fontFamily};
          --font-aeonikFono: ${aeonikFono.style.fontFamily};
          --font-aeonikMono: ${aeonikMono.style.fontFamily};
        }
      `}</style>
      {/* NOTE: we need to define the font variables this way or we don't get styles for modal components defined in _document.tsx since they would not be children of the main element here and don't have access to the css var (source: https://github.com/vercel/next.js/discussions/42023) */}
      <ABTestsContext.Provider value={abTests}>
        <MixpanelContext.Provider value={mixpanel}>
          <QueryClientProvider client={queryClient}>
            <NotificationQueueContext.Provider
              value={{
                notifications,
                setNotifications,
              }}
            >
              <UserReportLessonFilesContext.Provider
                value={{
                  userReportLessonFiles,
                  setUserReportLessonFiles,
                }}
              >
                <HydrationBoundary state={pageProps.dehydratedReactQueryState}>
                  <RoutingHistoryContext.Provider
                    value={{
                      routingHistoryEntries,
                      setRoutingHistoryEntries,
                    }}
                  >
                    <Head>
                      <title>
                        Learn to code | Mimo: Python, JavaScript, HTML, CSS, and
                        more
                      </title>
                      <meta charSet="UTF-8"></meta>
                      <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1"
                        key="viewport"
                      ></meta>
                      <meta
                        name="description"
                        content="Learn to code at your own pace. Start for free with our comprehensive courses in Python, JavaScript, HTML, and CSS, and become a professional developer."
                        key="description"
                      ></meta>
                      <meta
                        name="og:title"
                        content="Mimo: The coding platform you need to learn Web Development, Python, and more."
                        key="og:title"
                      ></meta>
                      <meta
                        name="og:image"
                        content={`https://mimo.org${link_image_twitter.src}`}
                        key="og:image"
                      ></meta>
                      <meta
                        name="og:description"
                        content="Mimo is a platform that teaches programming, HTML, CSS, JavaScript, and more through gamified and interactive lessons on the go."
                        key="og:description"
                      ></meta>
                      <meta
                        name="og:url"
                        content="https://mimo.org"
                        key="og:url"
                      ></meta>
                      <meta
                        name="og:type"
                        content="website"
                        key="og:type"
                      ></meta>
                      <meta
                        name="og:site_name"
                        content="Mimo: The coding platform you need to learn Web Development, Python, and more."
                        key="og:site_name"
                      ></meta>
                      <meta
                        name="fb:app_id"
                        content={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}
                        key="fb:app_id"
                      ></meta>
                      <meta
                        name="twitter:url"
                        content="https://mimo.org"
                        key="twitter:url"
                      ></meta>
                      <meta
                        name="twitter:title"
                        content="Mimo: The coding platform you need to learn Web Development, Python, and more."
                        key="twitter:title"
                      ></meta>
                      <meta
                        name="twitter:description"
                        content="Mimo is a platform that teaches programming, HTML, CSS, JavaScript, and more through gamified and interactive lessons on the go."
                        key="twitter:description"
                      ></meta>
                      <meta
                        name="twitter:card"
                        content="summary_large_image"
                        key="twitter:card"
                      ></meta>
                      <link rel="canonical" href={canonicalUrl} />
                      <link
                        rel="apple-touch-icon"
                        sizes="57x57"
                        href="/apple-icon-57x57.png"
                      />
                      <link
                        rel="apple-touch-icon"
                        sizes="60x60"
                        href="/apple-icon-60x60.png"
                      />
                      <link
                        rel="apple-touch-icon"
                        sizes="72x72"
                        href="/apple-icon-72x72.png"
                      />
                      <link
                        rel="apple-touch-icon"
                        sizes="76x76"
                        href="/apple-icon-76x76.png"
                      />
                      <link
                        rel="apple-touch-icon"
                        sizes="114x114"
                        href="/apple-icon-114x114.png"
                      />
                      <link
                        rel="apple-touch-icon"
                        sizes="120x120"
                        href="/apple-icon-120x120.png"
                      />
                      <link
                        rel="apple-touch-icon"
                        sizes="144x144"
                        href="/apple-icon-144x144.png"
                      />
                      <link
                        rel="apple-touch-icon"
                        sizes="152x152"
                        href="/apple-icon-152x152.png"
                      />
                      <link
                        rel="apple-touch-icon"
                        sizes="180x180"
                        href="/apple-icon-180x180.png"
                      />
                      <link
                        rel="icon"
                        type="image/png"
                        sizes="192x192"
                        href="/android-icon-192x192.png"
                      />
                      <link
                        rel="icon"
                        type="image/png"
                        sizes="32x32"
                        href="/favicon-32x32.png"
                      />
                      <link
                        rel="icon"
                        type="image/png"
                        sizes="96x96"
                        href="/favicon-96x96.png"
                      />
                      <link
                        rel="icon"
                        type="image/png"
                        sizes="16x16"
                        href="/favicon-16x16.png"
                      />
                    </Head>
                    <Script
                      id="_cio-script"
                      strategy="lazyOnload"
                      dangerouslySetInnerHTML={{
                        __html: `
                  var _cio = _cio || [];
                  (function() {
                    var a,b,c;a=function(f){return function(){_cio.push([f].
                    concat(Array.prototype.slice.call(arguments,0)))}};b=["load","identify",
                    "sidentify","track","page"];for(c=0;c<b.length;c++){_cio[b[c]]=a(b[c])};
                    var t = document.createElement('script'),
                        s = document.getElementsByTagName('script')[0];
                    t.async = true;
                    t.id    = 'cio-tracker';
                    t.setAttribute('data-site-id', '216c7066473188adea6e');
                    t.setAttribute('data-use-in-app', 'true');
                    t.setAttribute('data-auto-track-page', 'false');
                    t.setAttribute('data-use-array-params', 'true');
                    t.src = 'https://assets.customer.io/assets/track.js';
                    s.parentNode.insertBefore(t, s);
                  })();`,
                      }}
                    />
                    {/* FACEBOOK PIXEL */}
                    <Script
                      strategy="lazyOnload"
                      dangerouslySetInnerHTML={{
                        __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}');
                fbq('track', 'PageView');`,
                      }}
                    ></Script>
                    <link
                      rel="stylesheet"
                      href="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.8.0/styles/github-dark.min.css"
                    ></link>
                    <noscript>
                      <img
                        alt="facebook pixel"
                        height="1"
                        width="1"
                        style={{ display: 'none' }}
                        src={`https://www.facebook.com/tr?id=${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1`}
                      />
                    </noscript>
                    <Script
                      strategy="lazyOnload"
                      dangerouslySetInnerHTML={{
                        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');`,
                      }}
                    />
                    <noscript>
                      <iframe
                        title="gtm"
                        src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_ID}`}
                        height="0"
                        width="0"
                        style={{ display: 'none', visibility: 'hidden' }}
                      ></iframe>
                    </noscript>
                    {/* TIKTOK PIXEL */}
                    <Script
                      strategy="lazyOnload"
                      dangerouslySetInnerHTML={{
                        __html: `
!function (w, d, t) {
  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};


  ttq.load('${process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID}');
  ttq.page();
}(window, document, 'ttq');`,
                      }}
                    ></Script>
                    <SpeedInsights sampleRate={0.1} />
                    <Component {...pageProps} />
                    <FlagValues values={abTests} />
                    {isMimoEmployee && <VercelToolbar />}
                  </RoutingHistoryContext.Provider>
                </HydrationBoundary>
              </UserReportLessonFilesContext.Provider>
            </NotificationQueueContext.Provider>
          </QueryClientProvider>
        </MixpanelContext.Provider>
      </ABTestsContext.Provider>
    </main>
  );
}

MyApp.getInitialProps = async ({ ctx }: { ctx: NextPageContext }) => {
  if (ctx.req) {
    const abTests = await parseABTestCookiesFromRequestHeader(ctx.req.headers);
    return { abTests };
  }

  return {};
};

// NOTE: this is the way then axiom integration works with
// the old pages router, if we upgrade to the app router we
// should follow the upgrade path in the axiom docs
export { reportWebVitals } from 'next-axiom';

export default withUser<any>()(MyApp);
